export const path = {
  correspondence: "/nouns",
  sentence: "/pronouns",
  login: "/login",
  profile: "/profile",
  speaking: "/verbs",
  teacherRoom: "/teacher-room",
  createLesson: "/create-lesson",
  classroom: "/classroom",
  editClass: "/edit-class",
  lessonsList: "/lessons-list",
  lessonResults: "/lesson-results",
  poem: "/poem",
  reading: "/read",
  lesson: "/lesson",
};
